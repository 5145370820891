import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { ChangePassword } from '../model/changePassword';
import { PasswordResetFinish } from '../model/passwordResetFinish';
import { Credentials } from '../model/credentials';
import { ApplicationUser } from '@/shared/model/applicationUser';
import { i18nGlobal } from '@/i18n';

export interface BackendAccount {
  authenticate: (
    username: string,
    password: string,
    rememberMe?: boolean
  ) => AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }>;
  authenticateGoogle: (id_token: string) => AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }>;
  authenticateFacebook(id_token: string): AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }>;
  getMyAccount: () => AxiosPromise<any>;
  getMyAccountWithPassword: (id: string) => AxiosPromise<ApplicationUser>;
  changeMyPassword: (oldPassword: string, newPassword: string) => AxiosPromise;
  initResetPassword: (email: string) => AxiosPromise;
  checkPasswordReset: (key: string) => AxiosPromise;
  finishResetPassword: (key: string, newPassword: string) => AxiosPromise;
  manualPasswordReset: (id: string) => AxiosPromise;
  logout: () => AxiosPromise;
  createAdmin: (payload: any) => AxiosPromise<ApplicationUser>;
  createContact: (payload: any) => AxiosPromise<ApplicationUser>;
  resendConfirmationEmail: (id: number) => AxiosPromise;
  impersonateUser: (userId: string) => AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }>;
  isResponsibleOrDeputy: () => AxiosPromise<boolean>;
  isUniqueEmail(email: string): AxiosPromise;
  resetPasswordByEmail(payload: {
    Email: string;
    UserName: string;
  }): AxiosPromise;
  setLanguage(lang: string): AxiosPromise;
}

export const defaultBackendAccount: BackendAccount = {
  authenticate(
    username: string,
    password: string,
    rememberMe?: boolean
  ): AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }> {
    const credentials: Credentials = {
      password,
      username,
      rememberMe,
      authorities: '',
      emailConfirmed: false,
      inactivityMinutes: 0,
      timeSheetFormat: 0,
    };
    return instance.post<any>(`${URLS.authenticate}`, credentials);
  },

  authenticateGoogle(id_token: string): AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }> {
    const credentials = {
      AccessToken: id_token,
    };

    return instance.post<any>(`${URLS.account}/GoogleLogin`, credentials);
  },

  authenticateFacebook(id_token: string): AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }> {
    const credentials = {
      AccessToken: id_token,
    };

    return instance.post<any>(`${URLS.account}/FacebookLogin`, credentials);
  },

  getMyAccount(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.account}/GetMe`);
  },
  getMyAccountWithPassword(id: string): AxiosPromise<ApplicationUser> {
    return instance.get<ApplicationUser>(`${URLS.account}/${id}/password`);
  },
  changeMyPassword(currentPassword: string, newPassword: string): AxiosPromise {
    const changePassword: ChangePassword = {
      currentPassword,
      newPassword,
    };
    return instance.post(`${URLS.account}/change-password`, changePassword);
  },
  initResetPassword(email: string): AxiosPromise {
    return instance.post(`${URLS.account}/reset-password-init`, email, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  checkPasswordReset(key: string): AxiosPromise {
    return instance.post(`${URLS.account}/reset-password-check`, key, {
      headers: { 'Content-Type': 'application/json' },
    });
  },
  finishResetPassword(key: string, newPassword: string): AxiosPromise {
    const passwordResetFinish: PasswordResetFinish = {
      key,
      newPassword,
    };
    return instance.post(
      `${URLS.account}/reset-password-finish`,
      passwordResetFinish
    );
  },
  manualPasswordReset(id: string) : AxiosPromise {
    const lang = i18nGlobal.locale.value;
    return instance.post(
      `${URLS.account}/ManualPasswordReset`,
      { ApplicationUserId: id, Language: lang }
    );
  },
  logout(): AxiosPromise {
    return instance.post(`${URLS.account}/logout`);
  },
  createAdmin(payload: any): AxiosPromise<ApplicationUser> {
    const lang = i18nGlobal.locale.value;
    const newEmployee = payload.newEmployee;
    const countryId = payload.countryId;
    return instance.post<ApplicationUser>(
      `${URLS.account}/CreateAdmin/${lang}/${countryId}`,
      {
        FirstName: newEmployee.firstName,
        LastName: newEmployee.lastName,
        FullName: newEmployee.fullName,
        Salutation: newEmployee.salutation,
        Email: newEmployee.email,
        Phone: newEmployee.phone,
        UserName: newEmployee.userName,
        Password: newEmployee.password,
        Authorities: newEmployee.authorities,
        Token: newEmployee.token,
        FromDate: newEmployee.fromDate,
        Active: newEmployee.active,
        Unlocked: newEmployee.unlocked,
        IsIntern: newEmployee.isIntern,
      }
    );
  },
  createContact(payload: any): AxiosPromise<ApplicationUser> {
    const lang = i18nGlobal.locale.value;
    const newEmployee = payload.newEmployee;
    const countryId = payload.countryId;
    return instance.post<ApplicationUser>(
      `${URLS.account}/CreateContact/${lang}/${countryId}`,
      {
        FirstName: newEmployee.firstName,
        LastName: newEmployee.lastName,
        FullName: newEmployee.fullName,
        Salutation: newEmployee.salutation,
        Email: newEmployee.email,
        Phone: newEmployee.phone,
        UserName: newEmployee.userName,
        Password: newEmployee.password,
        Authorities: newEmployee.authorities,
        Token: newEmployee.token,
      }
    );
  },
  resendConfirmationEmail(id: number): AxiosPromise {
    const lang = i18nGlobal.locale.value;
    return instance.post(
      `${URLS.account}/ResendConfirmationEmail/${id}/${lang}`
    );
  },

  impersonateUser(userId: string): AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }> {
    return instance.post<any>(`${URLS.account}/ImpersonateUser/${userId}`);
  },

  isResponsibleOrDeputy(): AxiosPromise<boolean> {
    return instance.get(`${URLS.employees}/IsResponsibleOrDeputy`);
  },
  isUniqueEmail(email: string): AxiosPromise {
    return instance.post(`${URLS.account}/IsUniqueEmail`, { Email: email });
  },
  resetPasswordByEmail(payload: {
    Email: string;
    UserName: string;
  }): AxiosPromise {
    return instance.post(
      `${URLS.account}/ResetPasswordByEmail/${i18nGlobal.locale.value}`,
      payload
    );
  },
  setLanguage(lang: string): AxiosPromise {
    return instance.put(`${URLS.employees}/UpdateLanguage`, { Language: lang });
  },
};
