import { defineRule, configure } from 'vee-validate';
import {
  required,
  email,
  confirmed,
  regex,
  min,
  max,
  ext,
  size,
  numeric,
  integer,
  max_value,
  min_value,
} from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import de from '@vee-validate/i18n/dist/locale/de.json';
import { localeMessages } from '@/i18n';
import {
  decimal,
  url,
  verifyAlreadyExistCompanyName,
  verifyAlreadyExistContactInternalEmail,
  verifyAlreadyExistEmail,
  verifyAlreadyExistMembernumber,
  verifyAlreadyExistUsername,
  verifyDateFormat,
  verifyFutureDate,
  verifyHours,
  verifyPassword,
  verifyPastDate,
  verifyPhone,
  verifyUsernameCharacters,
  verifyValidIban,
} from "@/shared/utils/validationRules";

defineRule('required', required);
defineRule('email', email);
defineRule('confirmed', confirmed);
defineRule('regex', regex);
defineRule('min', min);
defineRule('max', max);
defineRule('numeric', numeric);
defineRule('integer', integer);
defineRule('max_value', max_value);
defineRule('min_value', min_value);
defineRule('ext', ext);
defineRule('size', size);

// custom validators
defineRule('verify_password', verifyPassword);
defineRule('verify_phone', verifyPhone);
defineRule('decimal', decimal);
defineRule('verify_already_exist_username', verifyAlreadyExistUsername);
defineRule('verify_username_characters', verifyUsernameCharacters);
defineRule('verify_already_exist_email', verifyAlreadyExistEmail);
defineRule('verify_already_exist_contactInternal_email', verifyAlreadyExistContactInternalEmail);
defineRule('verify_already_exist_membernumber', verifyAlreadyExistMembernumber);
defineRule('verify_already_exist_companyname', verifyAlreadyExistCompanyName);
defineRule('verify_hours', verifyHours);
defineRule('url', url);
defineRule('verify_date_format', verifyDateFormat);
defineRule('verify_valid_iban', verifyValidIban);
defineRule('verify_past_date', verifyPastDate);
defineRule('verify_future_date', verifyFutureDate);

configure({
  generateMessage: localize({
    en,
    de,
  }),
});

configure({
  generateMessage: localize(localeMessages),
});
