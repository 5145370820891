import { MutationTree } from 'vuex';
import { AttendeeState } from './types';
import attendee, { Value } from '@/shared/model/attendee';

export const mutations: MutationTree<AttendeeState> = {
  setAttendees(state, payload: Value) {
    state.attendees.items = payload.value.map((x) => attendee.parse(x));
    state.attendees.total = payload['@odata.count'] || 0;
    state.attendees.isLoading = false;
  },
  setAttendeesTotal(state, payload: number) {
    state.attendees.total = payload;
  },
  setAttendeesIsLoading(state, payload: boolean) {
    state.attendees.isLoading = payload;
  },

  setInterested(state, payload: Value) {
    state.interested.items = payload.value.map((x) => attendee.parse(x));
    state.interested.total = payload['@odata.count'] || 0;
    state.interested.isLoading = false;
  },

  setInterestedTotal(state, payload: number) {
    state.interested.total = payload;
  },
  setInterestedIsLoading(state, payload: boolean) {
    state.interested.isLoading = payload;
  },

  setWaitingList(state, payload: Value) {
    state.waitingList.items = payload.value.map((x) => attendee.parse(x));
    state.waitingList.total = payload['@odata.count'] || 0;
    state.waitingList.isLoading = false;
  },

  setsetWaitingListTotal(state, payload: number) {
    state.waitingList.total = payload;
  },
  setWaitingListIsLoading(state, payload: boolean) {
    state.waitingList.isLoading = payload;
  },

  resetAttendeesPaging(state) {
    state.attendees.searchParams.dataOption.page = 1;
    state.attendees.searchParams.dataOption.itemsPerPage = 10;
    state.attendees.searchParams.dataOption.filter = '';
    state.attendees.searchParams.filter = '';
  },
  resetInterestedPaging(state) {
    state.interested.searchParams.dataOption.page = 1;
    state.interested.searchParams.dataOption.itemsPerPage = 10;
    state.interested.searchParams.dataOption.filter = '';
    state.interested.searchParams.filter = '';
  },

  setAttendeesWithoutMembershipTotalOpenCount(state, payload: Value) {
    state.attendeesWithoutMembershipTotalOpenCount = payload['@odata.count'] ?? 0;
  },
};
