import DateUtils from '../utils/dateUtils';

export interface ReferenceParent {
  id?: string; // System.Guid
  refName: string;
  text: string; // string
  level: string; // string
  fromDate?: string | null; // System.DateTime
  tillDate?: string | null; // System.DateTime
  referenceId?: string; // System.Guid
  parentId?: string; // System.Guid
  documentId?: string;
  companyId?: string;
  type: string;
  creatorFullName: string;
  creatorId?: string;
  createdDate: string; // System.DateTime
  idNumber: string;
  refParentId?: string; // Helper variable to retrieve the id of membership subscriptions of an attendee
  isActiveAtDate(date: string): boolean;
  readonly isExpired: boolean;
  readonly beginsInFuture: boolean;
  readonly isCurrentlyActive: boolean;
}

export type ReferenceParentTable =
  | 'SkillTrainer'
  | 'SkillCourse'
  | 'SkillDateTime'
  | 'LicenseTrainer'
  | 'CharacteristicAttendee'
  | 'MembershipTypeAttendee'
  | 'EquipmentCourse'
  | 'CostMembershipType'
  | 'AttendeeRoleAttendee';
export const ReferenceParentTable = {
  SkillTrainer: 'SkillTrainer' as ReferenceParentTable,
  SkillCourse: 'SkillCourse' as ReferenceParentTable,
  SkillDateTime: 'SkillDateTime' as ReferenceParentTable,
  LicenseTrainer: 'LicenseTrainer' as ReferenceParentTable,
  CharacteristicAttendee: 'CharacteristicAttendee' as ReferenceParentTable,
  MembershipTypeAttendee: 'MembershipTypeAttendee' as ReferenceParentTable,
  EquipmentCourse: 'EquipmentCourse' as ReferenceParentTable,
  CostMembershipType: 'CostMembershipType' as ReferenceParentTable,
  AttendeeRoleAttendee: 'AttendeeRoleAttendee' as ReferenceParentTable,
};

export interface Value {
  value: ReferenceParent[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ReferenceParent>): any {
  return {
    Id: data?.id || undefined,
    Text: data?.text || '',
    Level: data?.level || '',
    FromDate: DateUtils.dateNullableToApi(data?.fromDate),
    TillDate: DateUtils.dateNullableToApi(data?.tillDate),
    ReferenceId: data?.referenceId || undefined,
    ParentId: data?.parentId || undefined,
    DocumentId: data?.documentId || undefined,
    CompanyId: data?.companyId || undefined,
    CreatorId: data?.creatorId || undefined,
    CreatedDate: data?.createdDate || undefined,
  };
}

// init api object
function arrayToAPI(data: Partial<ReferenceParent[]>): any {
  return data.map((item?: ReferenceParent) => {
    return {
      Id: item?.id || undefined,
      ReferenceId: item?.referenceId || undefined,
      ParentId: item?.parentId || undefined,
      Text: item?.text || '',
      Level: item?.level || '',
      FromDate: DateUtils.dateNullableToApi(item?.fromDate),
      TillDate: DateUtils.dateNullableToApi(item?.tillDate),
      DocumentId: item?.documentId || undefined,
      CompanyId: item?.companyId || undefined,
      CreatorId: item?.creatorId || undefined,
      CreatedDate: item?.createdDate || undefined,
    };
  });
}

function parse(data?: Partial<ReferenceParent & {name: string}>): ReferenceParent {
  return {
    id: data?.id || data?.refParentId || undefined,
    refName: data?.refName || data?.name || '',
    text: data?.text || '',
    level: data?.level || '',
    fromDate: DateUtils.parseDateFromBackend(data?.fromDate),
    tillDate: DateUtils.parseDateFromBackend(data?.tillDate),
    referenceId: data?.referenceId || '',
    parentId: data?.parentId || '',
    documentId: data?.documentId || undefined,
    createdDate: data?.createdDate || '',
    companyId: data?.companyId || '',
    type: data?.type || '',
    creatorFullName: data?.creatorFullName || '',
    creatorId: data?.creatorId || undefined,
    idNumber: data?.idNumber || '',
    isActiveAtDate(date: string): boolean {
      let dateIso = DateUtils.formatDateIsoNoOffset(date)!;
      return (
        (!this.fromDate ||
          dateIso >= DateUtils.formatDateIsoNoOffset(this.fromDate)!) &&
        (!this.tillDate ||
          dateIso <= DateUtils.formatDateIsoNoOffset(this.tillDate)!)
      );
    },
    get isExpired() {
      if (!this.tillDate) return false;
      let dateIso = DateUtils.formatDateIsoNoOffset(this.tillDate)!;
      return dateIso < DateUtils.getTodayDateAsIsoString();
    },
    get beginsInFuture() {
      if (!this.fromDate) return false;
      let dateIso = DateUtils.formatDateIsoNoOffset(this.fromDate)!;
      return dateIso > DateUtils.getTodayDateAsIsoString();
    },
    get isCurrentlyActive() {
      return this.isActiveAtDate(DateUtils.getTodayDateAsIsoString());
    },
  };
}

export default {
  parse,
  toAPI: toAPI,
  arrayToAPI,
};
