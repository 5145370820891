import { CONST } from '../utils/constants';
import DateUtils from '../utils/dateUtils';
import { formatFullName } from '../utils/generalUtils';

export interface TaskRequest {
  id?: string;
  status: TaskRequestStatus;
  roomName: string;
  attendeeFullName: string;
  attendeePhone: string;
  courseId?: string;
  trainerId?: string;
  dateTimeId: string;
  courseTrainerId?: string; // System.Guid
  courseName: string;
  trainerFullName: string;
  timeFrom: string;
  timeTill: string;
  readonly timeFromTill: string;
  readonly bookingDateAndTime: string;
  readonly isPassedWithTime: boolean;
  locationName: string;
  attendeeId?: string; // System.Guid?
  appointmentId?: string; // System.Guid?
  bookingDate: string; // System.DateTime
  referenceId?: string;
  ipAddress: string; // string
  creatorFullName: string;
  canceledDate: string; // System.DateTime
  canceledByFullName: string;
  countCourseMax: number;
  countOfAttendancesForTheDate: number;
  departmentNames: string;
  checkedInDate: string; // System.DateTime
  createdDate: string;
}
export type TaskRequestStatus = 'OPEN' | 'ACCEPT' | 'REJECT';
export const TaskRequestStatus = {
  Open: 'OPEN' as TaskRequestStatus,
  Accept: 'ACCEPT' as TaskRequestStatus,
  Reject: 'REJECT' as TaskRequestStatus,
};

export type TaskRequestTable =
  | 'TaskRequestAttendances'
  | 'Attendee'
  | 'Invoices'
  | 'EmployeeTrainer'
  | 'CourseTimes'
  | 'Notes'
  | 'MembershipRequests'
  | 'AttendeesWithoutMembership';
export const TaskRequestTable = {
  Attendances: 'TaskRequestAttendances' as TaskRequestTable,
  Attendee: 'Attendee' as TaskRequestTable,
  Invoices: 'Invoices' as TaskRequestTable,
  EmployeeTrainer: 'EmployeeTrainer' as TaskRequestTable,
  CourseTimes: 'CourseTimes' as TaskRequestTable,
  Notes: 'Notes' as TaskRequestTable,
  MembershipRequests: 'MembershipRequests' as TaskRequestTable,
  AttendeesWithoutMembership: 'AttendeesWithoutMembership' as TaskRequestTable,
} as const;

export interface Value {
  value: TaskRequest[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<TaskRequest>): any {
  return {
    Id: data?.id ?? undefined,
    ReferenceId: data?.referenceId ?? undefined,
    status: data?.status || TaskRequestStatus.Accept,
  };
}

function parse(data?: Partial<TaskRequest>): TaskRequest {
  return {
    id: data?.id,
    status: data?.status ?? TaskRequestStatus.Accept,
    roomName: data?.roomName ?? '',
    attendeeFullName: formatFullName(data?.attendeeFullName),
    attendeePhone: data?.attendeePhone ?? '',
    courseId: data?.courseId || undefined,
    trainerId: data?.trainerId || undefined,
    dateTimeId: data?.dateTimeId ?? CONST.emptyGuid,
    courseTrainerId: data?.courseTrainerId || undefined,
    attendeeId: data?.attendeeId || undefined,
    appointmentId: data?.appointmentId || undefined,
    bookingDate: data?.bookingDate || new Date().toISOString().substring(0, 10),
    ipAddress: data?.ipAddress || '',
    courseName: data?.courseName || '',
    trainerFullName: formatFullName(data?.trainerFullName),
    timeFrom: data?.timeFrom?.substring(0, 5) || '',
    timeTill: data?.timeTill?.substring(0, 5) || '',
    get timeFromTill() {
      return `${this.timeFrom} - ${this.timeTill}`;
    },
    get bookingDateAndTime() {
      return `${this.bookingDate.substring(0, 10)} ${this.timeFrom}`;
    },
    get isPassedWithTime() {
      return (
        this.bookingDateAndTime < DateUtils.getCurrentLocalDateAsIsoString()
      );
    },
    locationName: data?.locationName || '',
    canceledDate: data?.canceledDate || '',
    canceledByFullName: data?.canceledByFullName || '',
    creatorFullName: data?.creatorFullName || '',
    countCourseMax: data?.countCourseMax || 0,
    countOfAttendancesForTheDate: data?.countOfAttendancesForTheDate || 0,
    departmentNames: data?.departmentNames || '',
    checkedInDate: data?.checkedInDate || '',
    createdDate: data?.createdDate || '',
    referenceId: data?.referenceId ?? '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
